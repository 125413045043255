<template>
  <section id="Inquiry">
    <!-- <iframe
      class="inquireFrame"
      src="https://usopen.formstack.com/forms/hospitality_luxury_suites"
      title="Hospitality Luxury Suites"
    ></iframe> -->
    <b-container>
      <iframe
        class="inquireFrame"
        src="https://usopen.vipfanportal.com/2020/forms/form.html"
      />
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Inquiry",
};
</script>

<style lang="scss" scoped>
#Inquiry {
  padding: 25px 0;
  .inquireFrame {
    width: calc(100% - 4px);
    height: 1200px;
    @media (max-width: 450px) {
      height: 1300px;
    }
  }
}
</style>